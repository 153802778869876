// import React, { useEffect, useRef } from "react";
// import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";

// const EmbeddedJitsiMeet = () => {
//   const { appointmentId } = useParams();
//   const jitsiContainerRef = useRef(null);

//   const user = useSelector((state) => state.user.user);

//   useEffect(() => {
//     if (window.JitsiMeetExternalAPI) {
//       const domain = process.env.REACT_APP_JITSI_DOMAIN;
//       const options = {
//         roomName: `room-4`,
//         parentNode: jitsiContainerRef.current,
//         width: "100%",
//         height: "100%",
//         userInfo: {
//           displayName: user.user_fname + " " + user.user_lname,
//         },
//         configOverwrite: {
//           disableInviteFunctions: true, // Deshabilitar todas las funciones de invitación
//           prejoinPageEnabled: false, // Desactiva la página de pre-unión
//         },
//         interfaceConfigOverwrite: {
//           TOOLBAR_BUTTONS: [
//             "microphone",
//             "camera",
//             "closedcaptions",
//             "desktop",
//             "fullscreen",
//             "fodeviceselection",
//             "hangup",
//             "profile",
//             "recording",
//             "livestreaming",
//             "etherpad",
//             "sharedvideo",
//             "settings",
//             "raisehand",
//             "videoquality",
//             "filmstrip",
//             "shortcuts",
//             "tileview",
//             "download",
//             "help",
//             "mute-everyone",
//             "security",
//           ],
//           SHOW_JITSI_WATERMARK: false,
//           HIDE_INVITE_MORE_HEADER: true,
//           SHOW_BRAND: false,
//           SHOW_APP_NAME: false,
//         },
//       };

//       const api = new window.JitsiMeetExternalAPI(domain, options);

//       // Opcional: Añadir más configuraciones de API si es necesario

//       return () => api.dispose();
//     } else {
//       console.error(
//         "JitsiMeetExternalAPI no está disponible. Asegúrate de que el script de Jitsi Meet se haya cargado correctamente."
//       );
//     }
//   }, [appointmentId]);

//   return (
//     <div ref={jitsiContainerRef} style={{ width: "100%", height: "100vh" }} />
//   );
// };

// export default EmbeddedJitsiMeet;

import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function EmbeddedJitsiMeet() {
  const location = useLocation();
  const jitsiContainerRef = useRef(null);

  const { jwtToken, roomName, type } = location.state || {};
  const user = useSelector((state) => state.user.user); // Obtener el usuario del store de Redux

  useEffect(() => {
    if (jwtToken && window.JitsiMeetExternalAPI) {
      const domain = process.env.REACT_APP_JITSI_DOMAIN;

      // Verificar si el usuario es moderador

      const isModerator = type === "moderator";

      const options = {
        roomName: roomName,
        parentNode: jitsiContainerRef.current,
        jwt: jwtToken,
        configOverwrite: {
          enableUserRolesBasedOnToken: true, // Asegura que los roles se basen en el token JWT
          defaultUserRole: "participant", // Establece el rol predeterminado como 'participant'
          prejoinPageEnabled: false, // Desactiva la página de pre-unión
          // Deshabilita funciones de moderador para usuarios no moderadores
          disableKick: !isModerator,
          disableMuteEveryone: !isModerator,
          disableModeratorIndicator: true,
          startWithAudioMuted: false,
          startWithVideoMuted: true,
        },
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: isModerator
            ? [
                "microphone",
                "camera",
                "closedcaptions",
                "desktop",
                "fullscreen",
                "fodeviceselection",
                "hangup",
                "chat",
                "profile",
                "recording",
                "livestreaming",
                "etherpad",
                "sharedvideo",
                "settings",
                "raisehand",
                "videoquality",
                "filmstrip",
                "shortcuts",
                "tileview",
                "download",
                "help",
                "mute-everyone",
                "security",
              ]
            : [
                "microphone",
                "fullscreen",
                "hangup",
                "chat",
                "raisehand",
                "tileview",
              ],
          SHOW_JITSI_WATERMARK: false,
          HIDE_INVITE_MORE_HEADER: true,
          SHOW_BRAND: false,
          SHOW_APP_NAME: false,
        },
      };

      // Inicializar la API de Jitsi
      const api = new window.JitsiMeetExternalAPI(domain, options);

      api.addEventListener("videoConferenceJoined", () => {
        console.log("Usuario ha entrado en la reunión");
        if (!isModerator) {
          // Intentar forzar el rol a 'participant' si no es moderador
          api.executeCommand("toggleModerator"); // Este comando puede no estar disponible
        }
      });
      // Limpiar cuando el componente se desmonta
      return () => api.dispose();
    }
  }, [jwtToken, user]);

  return (
    <div>
      <div
        ref={jitsiContainerRef}
        style={{ height: "100vh", width: "100%" }}
      ></div>
    </div>
  );
}
