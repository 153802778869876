//** Import Components */
import { Table, Empty, Space, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import JitsiMeetUser from "../routes/BusinessDashboard/pages/JitsiMeet/JitsiMeetUser";
import { TABLE_DATE_FORMAT, TABLE_HOUR_FORMAT } from "../common/constants";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const useManagementEventsUser = () => {
  const navigate = useNavigate();

  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    // {
    //   title: "ID",
    //   key: "appointment_id",
    //   render: (text) => <>{text.id}</>,
    // },
    {
      title: "Event Name",
      key: "ad_name",
      render: (text) => <>{text.event[0].name}</>,
    },
    {
      title: "Business Name",
      key: "ad_name",
      render: (text) => <>{text.business[0].name}</>,
    },
    {
      title: "Date",
      key: "event_date",
      render: (text) => (
        <>
          {moment
            .utc(
              text.meta_data.find((meta) => meta.meta_key === "event_date")
                .meta_value
            )
            .format(TABLE_DATE_FORMAT)}
        </>
      ),
    },
    {
      title: "Start Hour",
      key: "start_hour",
      render: (text) => (
        <>
          {moment(
            text.meta_data.find((meta) => meta.meta_key === "event_starttime")
              .meta_value,
            "HH:mm"
          ).format("hh:mm A")}
        </>
      ),
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <>
          {/* {text.status === "pending" && (
            <Space size="middle">
              {console.log("text", text)}
              <button
                onClick={() => {
                  navigate(`${text.id}`);
                }}
              >
                Manage
              </button>
            </Space>
          )} */}
          <JitsiMeetUser product={text} type="event" />
        </>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table
              columns={columns}
              dataSource={listItems}
              scroll={{ x: 1000 }}
            />
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [ListComponent];
};
