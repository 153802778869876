import React, { useEffect, useState } from "react";
import RestaurantInfo from "./RestaurantInfo";
import { useParams } from "react-router";
import ListBackBtn from "../../components/ListBackBtn";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function RestaurantSettings() {
  //* Get the restaurant Data
  const { id } = useParams();

  const [restaurant, setRestaurant] = useState();

  useEffect(() => {
    getRestaurantData();
  }, [id]);

  const getRestaurantData = async () => {
    const response = await axiosWithAuth().get(
      `${process.env.REACT_APP_BE_API_URL}/api/products/${id}`
    )

    setRestaurant(response.data)
  }

  return (
    <>
      <ListBackBtn backURL='/business/menu' btnTxt='Back To Menu Item List' />
      {
        restaurant !== undefined && (
          <RestaurantInfo restaurant={restaurant} />
        )
      }
    </>
  );
}
