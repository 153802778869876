//** Import Components */
import { Table, Empty, Space, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import JitsiMeetUser from "../routes/BusinessDashboard/pages/JitsiMeet/JitsiMeetUser";

export const useManagementAppointmentsUser = () => {
  const navigate = useNavigate();

  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    {
      title: "Service Name",
      key: "ad_name",
      render: (text) => <>{text.service.name}</>,
    },
    {
      title: "Business Name",
      key: "ad_name",
      render: (text) => <>{text.service.business[0].name}</>,
    },
    {
      title: "Date",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.start_time)).format(dateFormat)}</>
      ),
    },
    {
      title: "Start Hour",
      key: "appointment_hour",
      render: (text) => <>{moment.utc(text.start_time).format("hh:mm A")}</>,
    },
    {
      title: "Duration",
      key: "appointment_hour",
      render: (text) => (
        <>
          {text.service.meta_data.find(
            (meta) => meta.meta_key === "service_duration_time"
          ).meta_value + " HOURS"}
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text) => (
        <>
          {text.status === "cancelled" && (
            <Tag icon={<ExclamationCircleOutlined />} color="error">
              {text && text.status}
            </Tag>
          )}
          {text.status === "postponed" && (
            <Tag icon={<ClockCircleOutlined />} color="warning">
              {text && text.status}
            </Tag>
          )}
          {text.status === "pending" && (
            <Tag icon={<SyncOutlined spin />} color="processing">
              {text && text.status}
            </Tag>
          )}
          {text.status === "confirmed" && (
            <Tag icon={<CheckCircleOutlined />} color="success">
              {text && text.status}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <>
          {text.status === "pending" && (
            <Space size="middle">
              {console.log("text", text)}
              <button
                onClick={() => {
                  navigate(`${text.id}`);
                }}
              >
                Manage
              </button>
            </Space>
          )}
          <JitsiMeetUser product={text} type="appointment" />
        </>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table
              columns={columns}
              dataSource={listItems}
              scroll={{ x: 1000 }}
            />
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [ListComponent];
};
