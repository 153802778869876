// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import moment from "moment";
// import axiosWithAuth from "../../../../utils/axiosWithAuth";

// export default function JitsiMeetUser(props) {
//   const { product, type } = props;
//   const [meetingUrl, setMeetingUrl] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchMeetingUrl = async () => {
//       try {
//         if (product) {
//           const jitsiMeta = product.meta_data.find(
//             (meta) => meta.meta_key === "meet_jitsi"
//           );
//           const startTimeMeta = product.meta_data.find(
//             (meta) =>
//               meta.meta_key ===
//               (type === "appointment"
//                 ? "appointment_starttime"
//                 : "event_starttime")
//           );
//           const endTimeMeta = product.meta_data.find(
//             (meta) =>
//               meta.meta_key ===
//               (type === "appointment" ? "appointment_endtime" : "event_endtime")
//           );
//           const eventDateMeta = product.meta_data.find(
//             (meta) =>
//               meta.meta_key ===
//               (type === "appointment" ? "appointment_date" : "event_date")
//           );

//           if (startTimeMeta && endTimeMeta && eventDateMeta) {
//             const startTimeValue = startTimeMeta.meta_value;
//             const endTimeValue = endTimeMeta.meta_value;
//             const eventDateValue = eventDateMeta.meta_value.split(" ")[0];

//             const eventStartDateTime = moment(
//               `${eventDateValue} ${startTimeValue}`,
//               "YYYY-MM-DD HH:mm:ss"
//             );
//             const eventEndDateTime = moment(
//               `${eventDateValue} ${endTimeValue}`,
//               "YYYY-MM-DD HH:mm:ss"
//             );
//             const now = moment();

//             if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
//               const minutesToStart = moment
//                 .duration(eventStartDateTime.diff(now))
//                 .asMinutes();
//               const minutesToEnd = moment
//                 .duration(eventEndDateTime.diff(now))
//                 .asMinutes();

//               if (jitsiMeta && minutesToStart <= 30 && minutesToEnd >= 0) {
//                 setMeetingUrl(jitsiMeta.meta_value);
//               }
//             } else {
//               console.log("Invalid startTime or endTime");
//             }
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching meeting URL:", error);
//       }
//     };

//     fetchMeetingUrl();
//   }, [product, type]);

//   const handleButtonClick = () => {
//     console.log("product", product);
//     if (meetingUrl) {
//       navigate(
//         `/jitsi-meet/${
//           type === "event" ? product.event_id : product.appintment_id
//         }`
//       );
//     }
//   };

//   if (!product) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <button onClick={handleButtonClick} disabled={!meetingUrl}>
//         Join Meeting
//       </button>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function JitsiMeetUser(props) {
  const { product, type } = props;
  const [meetingUrl, setMeetingUrl] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [roomName, setRoomName] = useState(
    product.meta_data?.find((meta) => meta.meta_key === "meet_jitsi")
      ?.meta_value
  );
  const user = useSelector((state) => state.user.user);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("product", product);
    const fetchMeetingData = async () => {
      try {
        if (product) {
          let startTime, endTime, eventDate, jitsiName;

          if (type === "event") {
            // Para eventos, obtenemos los datos de meta_data
            const jitsiMeta = product.meta_data.find(
              (meta) => meta.meta_key === "meet_jitsi"
            );
            const startTimeMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_starttime"
            );
            const endTimeMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_endtime"
            );
            const eventDateMeta = product.meta_data.find(
              (meta) => meta.meta_key === "event_date"
            );

            if (startTimeMeta && endTimeMeta && eventDateMeta) {
              startTime = startTimeMeta.meta_value;
              endTime = endTimeMeta.meta_value;
              eventDate = eventDateMeta.meta_value.split(" ")[0];
              jitsiName = jitsiMeta?.meta_value;
            }
          } else if (type === "appointment") {
            startTime = product.start_time;
            const durationMeta = product.service.meta_data.find(
              (meta) => meta.meta_key === "service_duration_time"
            );
            if (durationMeta) {
              const duration = durationMeta.meta_value;
              endTime = moment(startTime)
                .add(moment.duration(duration))
                .format("HH:mm:ss");
            }
            eventDate = product.start_time.split("T")[0];
            jitsiName = product.meta_data?.find(
              (meta) => meta.meta_key === "meet_jitsi"
            )?.meta_value;
          }

          if (startTime && endTime && eventDate) {
            const eventStartDateTime = moment(
              `${eventDate} ${startTime}`,
              "YYYY-MM-DD HH:mm:ss"
            );
            const eventEndDateTime = moment(
              `${eventDate} ${endTime}`,
              "YYYY-MM-DD HH:mm:ss"
            );
            const now = moment();

            if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
              const minutesToStart = moment
                .duration(eventStartDateTime.diff(now))
                .asMinutes();
              const minutesToEnd = moment
                .duration(eventEndDateTime.diff(now))
                .asMinutes();

              // if (jitsiName && minutesToStart <= 30 && minutesToEnd >= 0) {
              setMeetingUrl(jitsiName);
              if (!jwtToken) {
                getJWT();
              }
              // }
            } else {
              console.log("Invalid startTime or endTime");
            }
          }
        }
      } catch (error) {
        console.error("Error fetching meeting data:", error);
      }
    };

    fetchMeetingData();
  }, [product, type, jwtToken]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const getJWT = async () => {
    const APIURL = `/api/jitsi-meet/accessAuthClient`;

    const eventData = {
      id: type === "event" ? product.event_id : product.id,
      owner_id: type === "event" ? product.user_id : product.service.id,
    };

    try {
      const res = await axiosWithAuth().post(APIURL, {
        //console.log("***roomName: ", roomName)
        // roomName: roomName,
        roomName: product.meta_data.find(
          (meta) => meta.meta_key === "meet_jitsi"
        )
          ? product.meta_data.find((meta) => meta.meta_key === "meet_jitsi")
              .meta_value
          : "default",
        user: {
          id: user.id,
          name: user.user_login,
          email: user.email,
        },
        eventData: eventData,
        typeEvent: type === "event" ? "event" : "appointment",
      });
      setJwtToken(res.data); // Guardar el JWT correctamente
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = () => {
    navigate(`/jitsi-meet/${roomName}`, {
      state: { jwtToken, roomName, type: "user" },
    });
  };

  // Nueva función para el segundo botón que redirige al servidor Jitsi específico
  const handleJitsiServerButtonClick = () => {
    const domain = process.env.REACT_APP_JITSI_DOMAIN;
    if (roomName && jwtToken) {
      const jitsiServerURL = `https://${domain}/${roomName}?jwt=${jwtToken}`;
      window.open(jitsiServerURL, "_blank");
    } else {
      console.error("Room name or JWT token is missing");
    }
  };

  return (
    <div>
      {/* <button
        onClick={handleButtonClick}
        disabled={!jwtToken}
      >
        Join Meeting
      </button> */}
      <button onClick={() => handleJitsiServerButtonClick()}>
        Join Meeting
      </button>
    </div>
  );
}
