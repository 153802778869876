import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useMangeManagementItem } from "../../../../hooks/useMangeManagementItem";
import { useManageCreateMenuItem } from "../../../../hooks/useManageCreateMenuItem";

import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { getRestaurantsList } from "../../../../features/reducers/restaurants";
import RestaurantSettings from "./RestaurantSettings";
import { Alert } from "antd";

export default function ManageRestaurant(props) {
  const { business } = props;

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const restaurantsList = useSelector((state) => {
    console.log("state", state);
    return state.restaurants.restaurantsList;
  });

  useEffect(() => {
    dispatch(getRestaurantsList(business.id));
  }, [business.id, dispatch]);

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    creatorID: business.id,
    dispatchAction: getRestaurantsList(business.id),
    deleteItemAPI: "/api/products/delete",
    user: user,
    itemType: "products",
    type: "restaurant",
    modalData: {
      title: "Create New Product",
      fieldLabel: "Product Name",
    },
  };

  // const [modalComponent, CreateItemButton, ListComponent] =
  //   useMangeManagementItem(hookData);

  const [modalComponent, CreateItemButton, ListComponent] =
    useManageCreateMenuItem(hookData);

  return (
    <div id="restaurant-page">
      <ContentPageTitle title="My Menu" icon="file-pen" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have a product listing."
                  btnText="Create New Product"
                  listItems={restaurantsList}
                />

                <CreateItemButton btnText="Create New Product" />
              </>
            }
            exact
          />

          <Route path="/:id" element={<RestaurantSettings />} exact />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
