import React, { useEffect } from "react";
import { Form, Input, Divider, Select, Button, InputNumber } from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import TextArea from "antd/lib/input/TextArea";
import LoadingForm from "../../../../common/LoadingForm";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";

export default function RestaurantInfo(props) {
    const { restaurant } = props;

    // Used to build the form
    const [form] = Form.useForm();

    const [
        fields,
        setFieldData,
        isSubmitting,
        submitForm,
        onFinishFailed,
        loadingForm,
    ] = useDashboardFormData(restaurant);

    const onFinish = async (values) => {
        submitForm(
            values,
            `/api/products/update/${restaurant.id}`
        );
    };

    useEffect(() => {
        if (restaurant.id !== undefined) {
            setFieldData();
        }
    }, [restaurant]);

    return (
        <>
            <ContentPageTitle title="Manage Menu Item" icon="pen-to-square" />

            <Divider />
                {loadingForm ? (
                    <LoadingForm />
                    ) : ( 
                        <div id="restaurants">
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={fields}
                                defaultValue
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                disabled={isSubmitting}
                            >
                                <div className="form-row">
                                    <div className="form-column-left">
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input menu item's name"
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Menu Item Name" />
                                        </Form.Item>

                                        <Form.Item
                                            name="meta_restaurant_description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input menu item's description"
                                                }
                                            ]}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Price"
                                            name="meta_restaurant_price"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input menu item's price"
                                                }
                                            ]}
                                        >
                                            <InputNumber addonBefore="$" placeholder="1.00" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Category"
                                            name="meta_restaurant_category"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select menu item's category"
                                                }
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 150 }}
                                                placeholder="Select"
                                                options={[
                                                    { value: 'breakfast', label: 'breakfast' },
                                                    { value: 'lunch', label: 'lunch' },
                                                    { value: 'dinner', label: 'dinner' },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="form-column-right">
                                        <Form.Item 
                                            name='meta_restaurant_logo'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input menu item's image"
                                                }
                                            ]}>
                                            <UploadMedia
                                                setMedia={(file) =>
                                                    form.setFieldsValue({
                                                        meta_restaurant_logo: file,
                                                    })
                                                }
                                                maxCount={1}
                                                label='Menu Item Image'
                                                fieldName='meta_restaurant_logo'
                                                form={form}
                                                description='400px * 400px Image - Max Size 10MB'
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                                <Divider />

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className='ant-btn ant-btn-primary' name="button_submit" loading={isSubmitting}>
                                        {isSubmitting ? "Saving" : "Save Settings"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    )}
        </>
    );
}
