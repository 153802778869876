import React from "react";
import { Tabs, Alert } from "antd";
import ServiceInfo from "./ServiceInfo";
import { useParams } from "react-router";
import ListBackBtn from "../../components/ListBackBtn";
import AvailabilityRules from "./AvailabilityRules";
import DayOffAvailabilityRules from "./DayOffAvailavilityRules";

export default function ServiceSettings(props) {
  //* Get the service Data
  const { id } = useParams();
  const { business } = props;

  return (
    <>
      <ListBackBtn
        backURL="/business/services"
        btnTxt="Back To Services List"
      />

      <div className="warning-boxes">
        <Alert
          message="Online Services"
          description={
            <>
              We currently use Zoom to broadcast online services. To start
              broadcasting an event, you will need to start a Zoom meeting. You
              may start a meeting by using the Zoom app, just make sure you use
              your Personal Room(under "New Meeting", make sure "Use My Personal
              Meeting ID" is selected). Alternatively, you may{" "}
              <a
                href="https://zoom.us/meeting#/upcoming"
                target="_blank"
                rel="noreferrer"
              >
                Click Here
              </a>{" "}
              to start a meeting via the web portal(go to "Personal Room", and
              click on "Start")
            </>
          }
          type="warning"
          showIcon
        />
        <br />
        {
          <div className="warning-boxes">
            <Alert
              message="Availibility Rules"
              description="Before your service is published on the site, you MUST create the availibility rules."
              type="warning"
              showIcon
            />
          </div>
        }
      </div>

      <Tabs defaultActiveKey="1" type="card" size="default">
        <Tabs.TabPane tab="Service Information" key="1">
          <ServiceInfo id={id} business={business} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Availability Rules" key="2">
          <AvailabilityRules id={id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Days Off" key="3">
          <DayOffAvailabilityRules id={id} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
