import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
// import HelpButton from "../../../../common/HelpButton";
import { useManageCategories } from "../../../../hooks/useManageCategories";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { getServicesList } from "../../../../features/reducers/services";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
// import ServiceSettings from "./ServiceSettings";
import { Alert } from "antd";

export default function ManageCategories(props) {
  const { business } = props;

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const servicesList = useSelector((state) => {
    return state.services.servicesList;
  });

  useEffect(() => {
    getCategoriesList();
  }, [business.id, dispatch]);

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    creatorID: business.id,
    // dispatchAction: getCategoriesList(),
    deleteItemAPI: "/api/products/delete",
    user: user,
    itemType: "categorie",
    type: "service",
    modalData: {
      title: "Create New Categorie",
      fieldLabel: "Categorie Name",
    },
  };

  const getCategoriesList = async () => {
    try {
      const APIURL = `/api/restaurant/dish/categories/${business.id}`;
      const res = await axiosWithAuth().get(APIURL);
      console.log("res", res);
    } catch (error) {
      console.log("Error getting categories: ", error);
    }
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useManageCategories(hookData);

  return (
    <div id="services-page">
      <ContentPageTitle title="My Services" icon="file-pen" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have an service listing."
                  btnText="Create New Service"
                  listItems={servicesList}
                />

                <CreateItemButton btnText="Create New Categorie" />
              </>
            }
            exact
          />
          {/* 
          <Route
            path="/:id"
            element={<ServiceSettings business={business} />}
            exact
          /> */}
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
